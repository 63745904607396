<template>
  <div id="menu">
    <!-- Installation button -->
    <p v-if="deferredPrompt">
      Get our free app. It won't take up space on your phone and also works
      offline!

      <button text @click="dismiss">Dismiss</button>
      <button text @click="install">Install</button>
    </p>

    <br />
    <button>Help</button>
    <button>About</button>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
  data() {
    return {
      deferredPrompt: null,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menu {
  margin-bottom: 40px;
  margin-top: 25px;
}

#menu button {
  background-color: #19c0c6;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

#menu button:hover {
  background-color: #159599;
}

#menu button:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
</style>
