<template>
  <div id="app">

    <MainMenu />
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import MainMenu from './components/MainMenu.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    MainMenu
  },
  mounted() {

  },
  methods: {

  },
  data() {
    return {

    }
  },
}
</script>

<style>
@font-face {
  font-family: "Mabook";
  src: local("Mabook"), url(./fonts/Mabook.otf) format("truetype");
}

body {
  background-color: #b9ceff;
}

button {
  font-family: "Mabook";
  font-size: 40px;
  filter: drop-shadow(2px 2px 2px rgba(74, 79, 129, 0.8));
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
