<template>
  <div>
    <img alt="Vue logo" src="../assets/travolta_transparent.gif" width="260px">
    <div class="hello">
      
      <button @click="startWatch()">
        <span v-if="geoWatch === undefined"> &lt; Find me &gt; </span>
        <span v-if="geoWatch !== undefined">Wait until good accuracy</span>
      </button>
      <p v-if="position.accuracy > 0">
        <!--
        <strong>Latitude:</strong> {{ position.latitude }} <br />
        <strong>Longitude:</strong> {{ position.longitude }} <br />
        -->
        <strong>Accuracy :</strong> {{ position.accuracy }} m <br />
      </p>
      <button v-if="position.latitude !== 0 && position.longitude !== 0" @click="shareLocation()">
        Share
      </button>
      <button v-if="position.latitude !== 0 && position.longitude !== 0" @click="copyToClipboard()">
        Copy to clipboard
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods: {
    copyToClipboard() {
      const textArea = document.createElement('textarea');
      textArea.value = `I'm here, more or less ${this.position.accuracy} meters ! http://maps.google.com/maps?q=${this.position.latitude},${this.position.longitude}`,
        document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
    },
    setCurrentLocation(p) {
      this.position.latitude = p.coords.latitude;
      this.position.longitude = p.coords.longitude;
      this.position.accuracy = Math.round(p.coords.accuracy);
    },
    startWatch() {
      if (!this.geoWatch) {
        if ("geolocation" in navigator && "watchPosition" in navigator.geolocation) {
          this.geoWatch = navigator.geolocation.watchPosition(this.setCurrentLocation, this.showLocationError, {
            enableHighAccuracy: true, timeout: 15000, maximumAge: 0
          });

        } else {
          alert("Geolocation is not supported by this browser.");
        }
      }
    },
    // Deal with errors
    showLocationError(error) {
      let message = "";
      switch (error.code) {
        case error.PERMISSION_DENIED:
          message = "User denied the request for Geolocation.";
          break;
        case error.POSITION_UNAVAILABLE:
          message = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          message = "The location request timed out.";
          break;
        case error.UNKNOWN_ERROR:
          message = "An unknown error occurred.";
          break;
      }
      return message;
    },
    shareLocation() {
      // Stop watching position
      navigator.geolocation.clearWatch(this.geoWatch);
      this.geoWatch = undefined;

      // Share location
      if (navigator.share) {
        navigator.share({
          title: 'Share My Spot',
          text: `I'm here, more or less ${this.position.accuracy} meters !`,
          url: `http://maps.google.com/maps?q=${this.position.latitude},${this.position.longitude}`,
        })
          .then(() => {
            console.log('Successful share')
            this.position = {
              latitude: 0,
              longitude: 0,
              accuracy: 0,
            }
          })
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Web Share API is not supported');
      }

    }
  },
  data() {
    return {
      geoWatch: undefined,
      position: {
        latitude: 0,
        longitude: 0,
        accuracy: 0,
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}


buttonOLD {
  background: linear-gradient(-70deg, #5c0e9c 0%, #f321e9 100%);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 100%;
}


mybutton {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fb7656;
  border: none;
  color: white;
  padding: 3px 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 40px;
  width: 90%;
}

button {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
  background-color: #3e8e41;
}

button:active {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.gradOLD {
  background: linear-gradient(-70deg, #5c0e9c 0%, #f321e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
